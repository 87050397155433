import * as envVars from './config';

interface Auth0Config {
  domain: string;
  clientID: string;
  audience: string;
  scope: string;
}

const LOCAL_DEV: Auth0Config = {
  domain: 'prm-dev.eu.auth0.com',
  clientID: '8Vbj0qdWt9o8aH0QIFT0xb9li7ZPO7VY',
  audience: 'https://test-api.prm.nsb.no',
  scope: 'openid profile manage:analyst manage:reference'
};

const DEV: Auth0Config = {
  domain: 'prm-dev.eu.auth0.com',
  clientID: '8Vbj0qdWt9o8aH0QIFT0xb9li7ZPO7VY',
  audience: 'https://test-api.prm.nsb.no',
  scope: 'openid profile manage:analyst manage:reference'
};

const TEST: Auth0Config = {
  domain: 'prm-dev.eu.auth0.com',
  clientID: '8Vbj0qdWt9o8aH0QIFT0xb9li7ZPO7VY',
  audience: 'https://test-api.prm.nsb.no',
  scope: 'openid profile manage:analyst manage:reference'
};

const PROD: Auth0Config = {
  domain: 'nsb-prm.eu.auth0.com',
  clientID: 'oZpF4I7bqld2ngQ1bZraEA6bpusBgPwa',
  audience: 'https://api.prm.nsb.no',
  scope: 'openid profile manage:analyst manage:reference'
};

function config(): Auth0Config {
  switch (process.env.REACT_APP_STAGE) {
    case 'development':
      return DEV;
    case 'test1':
    case 'test2':
      return TEST;
    case 'prod':
      return PROD;
    default:
      return LOCAL_DEV;
  }
}

export default {
  // Add common config values here
  ...config(),
  callback: envVars.default.callbackUrl
};
