import { ConfigProvider } from 'antd';
import enGB from 'antd/es/locale-provider/en_GB';
import 'moment/locale/en-gb';
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastProvider } from 'react-toast-notifications';
import App from './features/App';
import registerServiceWorker from './registerServiceWorker';
import './i18n';

declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    __REDUX_DEVTOOLS_EXTENSION__: any;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    /* eslint-enable */
  }
}

const render = (): void => {
  ReactDOM.render(
    <ConfigProvider locale={enGB}>
      <ToastProvider placement="top-center">
        <App />
      </ToastProvider>
    </ConfigProvider>,
    document.getElementById('root') as HTMLElement
  );
};

render();

registerServiceWorker();
