import React, { FC, Suspense, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { history } from '../../configureStore';
import { Spin } from 'antd';
import { css } from 'emotion';
import { Router } from 'react-router';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import auth0Config from '../../auth0Config';
import { CustomApolloProvider } from '../../apolloConfig';

const HomePage = React.lazy(() => import('../HomePage'));
const SearchPage = React.lazy(() => import('../SearchPage'));
const CalendarPage = React.lazy(() => import('../CalendarPage'));
const IntercityPage = React.lazy(() => import('../IntercityPage'));
const CampaignPage = React.lazy(() => import('../CampaignPage'));
const AdminPage = React.lazy(() => import('../AdminPage'));
const FlexibleFullPricePage = React.lazy(() =>
  import('../FlexibleFullPricePage')
);

const spinnerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
`;

const Spinner = (): React.ReactElement => (
  <div className={spinnerStyle}>
    <Spin size="large" tip={'Loading'} />
  </div>
);

function LazyComponent<P>(
  Component: React.ComponentType<P>
): React.ComponentType<P> {
  // eslint-disable-next-line react/display-name
  return (props: P) => <Component {...props} />;
}

const AppRouting: FC = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isAuthenticated) {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/home" />} />
          <Route path="/home" component={LazyComponent(HomePage)} />
          <Route path="/calendar" component={LazyComponent(CalendarPage)} />
          <Route path="/campaign" component={LazyComponent(CampaignPage)} />
          <Route path="/search" component={LazyComponent(SearchPage)} />
          <Route path="/intercity" component={LazyComponent(IntercityPage)} />
          <Route path="/admin" component={LazyComponent(AdminPage)} />
          <Route
            path="/flexible-full-price"
            component={LazyComponent(FlexibleFullPricePage)}
          />
        </Switch>
      </Suspense>
    );
  } else {
    return <Spinner />;
  }
};

const App: FC = () => (
  <Router history={history}>
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientID}
      audience={auth0Config.audience}
      scope={auth0Config.scope}
      redirectUri={auth0Config.callback}
      cacheLocation={'localstorage'}
      useRefreshTokens={true}
    >
      <CustomApolloProvider>
        <AppRouting />
      </CustomApolloProvider>
    </Auth0Provider>
  </Router>
);

export default App;
