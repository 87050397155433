import { ErrorResponse } from 'apollo-link-error';
import { history } from './configureStore';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import config from './config';

import introspectionQueryResultData from './fragmentTypes.json';
import { ServerParseError, ServerError } from 'apollo-link-http-common';
import React, { FC, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import { Operation } from 'apollo-link';
import { NormalizedCacheObject } from 'apollo-cache-inmemory/lib/types';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const UNAUTHORIZED = 'UNAUTHORIZED';

function hasStatusCode(
  networkError: Error | ServerError | ServerParseError
): networkError is ServerError | ServerParseError {
  return (
    (networkError as ServerError | ServerParseError).statusCode !== undefined
  );
}

const errorHandler = (error: ErrorResponse): void => {
  const { graphQLErrors, networkError } = error;
  if (graphQLErrors && graphQLErrors.filter(e => e).length > 0) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      if (message === UNAUTHORIZED) {
        history.push('/home');
      }
    });

    if (networkError) {
      console.warn(`[Network error]: ${networkError}`);
      if (hasStatusCode(networkError) && networkError.statusCode === 401) {
        console.warn(
          `An unauthorized call was made. Removing token and redirecting to home`
        );
        history.push('/home');
      }
    }
  }
};

type GetAccessTokenSilentlyFn = (
  options?: GetTokenSilentlyOptions
) => Promise<string>;

const requestHandler = (
  getAccessTokenSilently: GetAccessTokenSilentlyFn
) => async (operation: Operation): Promise<void> => {
  const token = await getAccessTokenSilently();
  operation.setContext({
    ...operation.getContext(),
    headers: {
      ...operation.getContext().headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  });
};

const createApolloClient = (
  getAccessTokenSilently: GetAccessTokenSilentlyFn
): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    uri: `${config.prmApiUrl}/graphql`,
    onError: errorHandler,
    request: requestHandler(getAccessTokenSilently),
    cache: new InMemoryCache({ fragmentMatcher })
  });

interface Props {
  children: React.ReactNode;
}

export const CustomApolloProvider: FC<Props> = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const apolloClient = useMemo(
    () => createApolloClient(getAccessTokenSilently),
    [getAccessTokenSilently]
  );

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
