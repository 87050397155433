interface Config {
  callbackUrl: string;
  prmApiUrl: string;
}

const LOCAL_DEV = {
  callbackUrl: 'http://localhost:3000/home',
  prmApiUrl: 'http://localhost:8080'
};

const DEV = {
  callbackUrl: 'http://localhost:3000/home',
  prmApiUrl: 'https://test1.api.prm.vy.no'
};

const TEST1 = {
  callbackUrl: 'https://test1.analyst.prm.vy.no/home',
  prmApiUrl: 'https://test1.api.prm.vy.no'
};

const TEST2 = {
  callbackUrl: 'https://test2.analyst.prm.vy.no/home',
  prmApiUrl: 'https://test2.api.prm.vy.no'
};

const PROD = {
  callbackUrl: 'https://analyst.prm.vy.no/home',
  prmApiUrl: 'https://api.prm.vy.no'
};

function config(): Config {
  switch (process.env.REACT_APP_STAGE) {
    case 'development':
      return DEV;
    case 'test1':
      return TEST1;
    case 'test2':
      return TEST2;
    case 'prod':
      return PROD;
    default:
      return LOCAL_DEV;
  }
}

export default {
  // Add common config values here
  maxAllocationFactorSum: 100,
  ...config()
};
